exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-admin-index-js": () => import("./../../../src/pages/admin/index.js" /* webpackChunkName: "component---src-pages-admin-index-js" */),
  "component---src-pages-docs-swagger-js": () => import("./../../../src/pages/docs/swagger.js" /* webpackChunkName: "component---src-pages-docs-swagger-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-marketplace-index-js": () => import("./../../../src/pages/marketplace/index.js" /* webpackChunkName: "component---src-pages-marketplace-index-js" */),
  "component---src-pages-marketplace-mdx-frontmatter-slug-js-content-file-path-home-runner-work-apiruns-site-apiruns-site-marketplace-colombia-compra-mdx": () => import("./../../../src/pages/marketplace/{mdx.frontmatter__slug}.js?__contentFilePath=/home/runner/work/apiruns-site/apiruns-site/marketplace/colombia-compra.mdx" /* webpackChunkName: "component---src-pages-marketplace-mdx-frontmatter-slug-js-content-file-path-home-runner-work-apiruns-site-apiruns-site-marketplace-colombia-compra-mdx" */),
  "component---src-pages-marketplace-payment-js": () => import("./../../../src/pages/marketplace/payment.js" /* webpackChunkName: "component---src-pages-marketplace-payment-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-signin-js": () => import("./../../../src/pages/signin.js" /* webpackChunkName: "component---src-pages-signin-js" */),
  "component---src-pages-signup-js": () => import("./../../../src/pages/signup.js" /* webpackChunkName: "component---src-pages-signup-js" */),
  "component---src-pages-summary-js": () => import("./../../../src/pages/summary.js" /* webpackChunkName: "component---src-pages-summary-js" */),
  "component---src-pages-users-password-js": () => import("./../../../src/pages/users/password.js" /* webpackChunkName: "component---src-pages-users-password-js" */),
  "component---src-pages-users-reset-js": () => import("./../../../src/pages/users/reset.js" /* webpackChunkName: "component---src-pages-users-reset-js" */),
  "component---src-pages-users-verify-js": () => import("./../../../src/pages/users/verify.js" /* webpackChunkName: "component---src-pages-users-verify-js" */),
  "component---src-pages-users-welcome-js": () => import("./../../../src/pages/users/welcome.js" /* webpackChunkName: "component---src-pages-users-welcome-js" */)
}

